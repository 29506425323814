<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card-text>
        <p class="text-center text-xl font-weight-semibold white--text mb-2">
          {{ t('pay_video.pay_video') }}
        </p>
        <p class="ma-0 pt-4 text-center">
          {{ t('pay_video.pay_video_detail') }}
        </p>
      </v-card-text>

      <v-card-text v-if="userData">
        <v-sheet>
          <v-slide-group
            v-model="itemSelected"
            mandatory
            show-arrows
          >
            <v-slide-item v-slot="{ active, toggle }">
              <v-card
                class="rounded-lg me-2"
                :class="{ 'white-border': active }"
                min-width="140"
                height="95"
                @click="toggle"
              >
                <v-card-text class="pa-3">
                  <div>
                    <v-icon
                      size="50"
                      :color="active ? 'white' : ''"
                    >
                      {{ icons.mdiCreditCardOutline }}
                    </v-icon>
                  </div>
                  <span :class="{ 'white--text': active }">
                    {{ t('pay_video.credit_card_new') }}
                  </span>
                </v-card-text>
              </v-card>
            </v-slide-item>

            <v-slide-item
              v-for="paymentMethod in paymentMethods"
              :key="paymentMethod.id"
              v-slot="{ active, toggle }"
            >
              <v-card
                class="rounded-lg me-2 d-flex"
                :class="{ 'white-border': active }"
                min-width="140"
                height="95"
                @click="toggle"
              >
                <v-card-text class="pa-3 d-flex align-center">
                  <div>
                    <v-img
                      v-if="paymentMethod.brand"
                      :src="require(`@/assets/images/cards/${paymentMethod.brand}.png`)"
                      width="50"
                      height="50"
                      :alt="`Payment Method ${paymentMethod.last_4}`"
                    />
                    <v-icon
                      v-else
                      size="50"
                      :color="active ? 'white' : ''"
                    >
                      {{ icons.mdiCreditCardOutline }}
                    </v-icon>
                  </div>
                  <div class="d-flex flex-column ml-2">
                    <span :class="{ 'white--text': active }">
                      {{ t('pay_video.ends') }} {{ paymentMethod.last_4 }}
                    </span>
                    <span :class="{ 'white--text': active }">
                      {{ t('pay_video.exp') }} {{ paymentMethod.exp_date_str }}
                    </span>
                  </div>
                </v-card-text>
              </v-card>
            </v-slide-item>
          </v-slide-group>
        </v-sheet>
      </v-card-text>

      <v-card-text>
        <p class="ma-0 py-3 mb-3 text-center text-start">
          {{ t('pay_video.card_information') }}
        </p>

        <v-form
          ref="form"
          @submit.prevent="formSubmit"
        >
          <v-text-field
            v-model="formData.card_holder"
            outlined
            rounded
            :placeholder="t('payment_methods.name_card')"
            :rules="[validators.required]"
            class="mb-1"
            :disabled="itemSelected !== 0"
          />

          <v-text-field
            v-model="formData.card_number"
            v-mask="'#### #### #### ####'"
            outlined
            rounded
            :placeholder="t('payment_methods.card_number')"
            :rules="validators.rules"
            class="mb-1"
            :disabled="itemSelected !== 0"
            @keyup="identifyCard"
          >
            <template v-slot:append>
              <div style="width: 100%">
                <v-icon
                  v-if="cardFilter === '*'"
                  color="primary"
                  size="30"
                >
                  {{ icons.mdiCreditCardOutline }}
                </v-icon>

                <v-img
                  v-else
                  height="30"
                  width="30"
                  :src="require(`@/assets/images/cards/${cardFilter}.png`)"
                />
              </div>
            </template>
          </v-text-field>

          <div class="d-flex justify-space-between">
            <v-text-field
              v-model="formData.card_date"
              v-mask="'##/##'"
              :label="t('payment_methods.expiration_date')"
              :placeholder="t('payment_methods.card_date')"
              class="mb-2 pr-2"
              :rules="[validators.required, validators.isValidDateRule]"
              outlined
              rounded
              hide-details
              :prepend-inner-icon="icons.mdiCalendar"
            />

            <v-text-field
              v-model="formData.cvv"
              outlined
              rounded
              :placeholder="t('payment_methods.cvv')"
              :rules="[validators.required, validators.integerValidator]"
              class="mb-1 pl-2"
              :disabled="itemSelected !== 0"
            />
          </div>

          <v-autocomplete
            v-model="formData.country"
            :items="phoneCodeOptions"
            item-value="value"
            item-text="name"
            :placeholder="t('users.country')"
            outlined
            rounded
            :rules="[validators.required]"
            class="mb-1"
            :disabled="itemSelected !== 0"
          >
            <template v-slot:item="{ item }">
              <img
                :src="item.image"
                :alt="item.name"
                width="25"
                height="auto"
                class="mr-2"
              />
              {{ item.name }}
            </template>
          </v-autocomplete>

          <v-text-field
            v-model="formData.zip_code"
            outlined
            rounded
            :placeholder="t('payment_methods.zip_code')"
            :rules="[validators.required]"
            class="mb-1"
            :disabled="itemSelected !== 0"
          />

          <v-checkbox
            v-if="userData"
            v-model="formData.save_credit_card"
            true-value="Y"
            false-value="N"
            hide-details
            class="mb-3"
            color="secondary"
            :disabled="itemSelected !== 0"
          >
            <template v-slot:label>
              <div>
                {{ t('pay_video.save_credit_card') }}
              </div>
            </template>
          </v-checkbox>

          <v-btn
            block
            size="x-large"
            color="secondary"
            type="submit"
            class="mt-6 text-capitalize"
            style="height: 50px !important"
          >
            {{ t('pay_video.pay') }} {{ formatCurrency(matchPrice) }}
          </v-btn>
        </v-form>
      </v-card-text>
    </div>
  </div>
</template>

<script>
/* eslint-disable import/no-cycle */
/* eslint-disable object-curly-newline */
/* eslint-disable no-confusing-arrow */
/* eslint-disable no-restricted-syntax */
import { mdiCreditCardOutline, mdiFoodAppleOutline, mdiCalendar } from '@mdi/js'
import { ref, computed, watch, onBeforeMount, onMounted, onUpdated } from '@vue/composition-api'
import { required, minLengthValidator, integerValidator } from '@core/utils/validation'
import { useUtils } from '@core/libs/i18n'
import { useRouter, formatCurrency } from '@core/utils'
import { success } from '@/@core/utils/toasted'
import themeConfig from '@themeConfig'
import Isotope from 'isotope-layout'
import useCryptoJs from '@core/utils/useCryptoJs'
import usePhone from '@core/utils/usePhone'
import CardValidator from 'card-validator'

import useVideoData from '@core/utils/useVideoData'

import { VueMaskDirective } from 'v-mask'
import Vue from 'vue'
import { addPayPremiumVideo } from '@api'

export default {
  setup() {
    const { t } = useUtils()
    const { userData, encrypt, decryptData } = useCryptoJs()
    const { phoneCodeOptions } = usePhone()
    const { router } = useRouter()
    const { videoData, currentVideoData } = useVideoData()

    const isLoading = ref(false)
    const formTest = ref(null)
    const elementRef = ref(null)

    const form = ref(null)
    const formData = ref({
      card_number: null,
      card_date: null,
      cvv: null,
      country: 'US',
      zip_code: null,
      save_credit_card: 'N',
    })
    const saveCreditCard = ref('N')
    const itemSelected = ref(0)
    const menu = ref(false)
    const modal = ref(false)
    const date = ref(
      new Date().toLocaleString('sv-SE', {
        timeZone: 'America/New_York',
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      }),
    )
    const cardFilter = ref('*')
    const result = computed(() => CardValidator.number(formData.value.card_number))
    const rules = [value => !!value || 'Required', () => result.value.isValid || 'Invalid Card Number']
    const iso = ref(null)
    const mountedIso = ref(false)
    const matchCode = ref(null)
    const matchPrice = ref(null)
    const videoId = ref(null)
    const videoOrderId = ref(null)
    const paymentMethods = ref([])

    const isFormValid = computed(() => {
      if (
        (itemSelected.value === 0
          && formData.value.card_number
          && formData.value.card_date
          && formData.value.cvv
          && formData.value.country
          && formData.value.zip_code)
        || itemSelected.value !== 0
      ) return true

      return false
    })

    const payVideo = async body => {
      const encryptInfo = encrypt(body)
      const respPay = await addPayPremiumVideo({ info: encryptInfo })
      if (respPay.ok) {
        success(respPay.message)
        if (matchCode.value) router.push({ path: `/game/${matchCode.value}` })
        else {
          const hash = videoData.value.video.video_share_url.split('/r/')[1]
          router.push({ name: 'recording', params: { hash } })
        }
      }
    }

    const capitalize = text => text
      .split(' ')
      .map(e => `${e.at(0).toUpperCase()}${e.substring(1, e.length)}`)
      .join(' ')

    // video_id: videoData.value && videoData.value.video.video_id ? videoData.value.video.video_id : null,
    const formSubmit = async () => {
      const body = {
        user_id: userData.value && userData.value.user_id ? userData.value.user_id : null,
        video_id: videoId.value ? videoId.value : null,
        video_order_id: videoOrderId.value ? videoOrderId.value : null,
        card_info: {},
      }
      if (itemSelected.value === 0 && form.value.validate()) {
        const [mounth, year] = formData.value.card_date.split('/')
        let fullYear = year
        if (year.length === 2) fullYear = `20${year}`
        body.card_info = {
          payment_method_id: null,
          save_card: formData.value.save_credit_card,
          name: capitalize(formData.value.card_holder),
          number: formData.value.card_number,
          exp_month: parseInt(mounth, 10),
          exp_year: parseInt(fullYear, 10),
          cvc: formData.value.cvv,
          country: formData.value.country,
          zip_code: formData.value.zip_code,
        }
        await payVideo(body)
      } else if (itemSelected.value !== 0) {
        const paymentMethod = paymentMethods.value[itemSelected.value - 1]
        body.card_info = {
          payment_method_id: paymentMethod.id,
          save_card: null,
          name: null,
          number: null,
          exp_month: null,
          exp_year: null,
          cvc: null,
          country: null,
          zip_code: null,
        }
        await payVideo(body)
      }
    }

    const identifyCard = () => {
      const niceType = result.value?.card?.niceType
      let filter = '*'
      let filterCard = '*'
      if (niceType) {
        const type = niceType.toLowerCase().replace(/\s/g, '')
        filter = `#${type}`
        filterCard = `${type}`
      }

      cardFilter.value = filterCard
      if (iso.value) iso.value.arrange({ filter })
    }

    const isValidDateRule = value => {
      if (value === null || value === undefined || value === '') {
        return true
      }

      const regex = /^((0[1-9])|(1[0-2]))\/(\d{2})$/
      const currentDate = new Date()
      const currentYear = currentDate.getFullYear() - 2000
      const currentMonth = currentDate.getMonth() + 1

      const dateParts = value.split('/')
      const expirationYear = parseInt(dateParts[1], 10)
      const expirationMonth = parseInt(dateParts[0], 10)

      if (!regex.test(value)) {
        return false
      }

      if (expirationYear < currentYear) {
        return false
      }

      if (expirationYear === currentYear && expirationMonth < currentMonth) {
        return false
      }

      return true
    }

    watch([itemSelected], () => {
      if (itemSelected.value !== 0) {
        setTimeout(() => {
          if (iso.value) iso.value.arrange({ filter: '*' })
        }, 100)
        formData.value.save_credit_card = 'N'
        saveCreditCard.value = 'N'
        form.value.reset()
      }
    })

    onBeforeMount(() => {
      Vue.directive('mask', VueMaskDirective)
    })

    onMounted(async () => {
      Object.assign(videoData.value, currentVideoData.value)
      matchCode.value = videoData.value.match
      matchPrice.value = videoData.value.price

      if (matchCode.value) {
        // videoOrderId.value = null
        const reference = decryptData(videoData.value.landingParams.reference)
        videoId.value = reference.video_id
      } else {
        // videoOrderId.value = null
        videoId.value = videoData.value.video.video_id
      }

      if (videoData.value.paymentMethodsParams) {
        paymentMethods.value = decryptData(videoData.value.paymentMethodsParams)
      }

      setTimeout(() => {
        if (iso.value) iso.value.arrange({ filter: '*' })
      }, 100)
    })

    onUpdated(() => {
      const grid = document.getElementById('grid')
      if (grid !== null && !mountedIso.value) {
        iso.value = new Isotope(grid, {
          itemSelector: '.card',
          masonry: {
            columnWidth: 25,
          },
        })
        setTimeout(() => {
          if (iso.value) iso.value.arrange({ filter: '*' })
        }, 100)
        mountedIso.value = true
      }
    })

    return {
      isLoading,
      formTest,
      elementRef,

      form,
      formData,
      saveCreditCard,
      itemSelected,
      menu,
      modal,
      date,
      matchCode,
      matchPrice,
      paymentMethods,
      videoData,
      cardFilter,
      userData,

      // computed
      isFormValid,
      phoneCodeOptions,

      // methods
      formSubmit,
      identifyCard,
      formatCurrency,

      // themeConfig
      appLogoTitle: themeConfig.app.logoTitle,

      // validations
      validators: {
        required,
        minLengthValidator,
        integerValidator,
        rules,
        isValidDateRule,
      },

      t,

      icons: {
        mdiCreditCardOutline,
        mdiFoodAppleOutline,
        mdiCalendar,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/pages/view.scss';
</style>

<style lang="scss" scoped>
.v-input__append-inner {
  max-width: auto 0px;
}

.white-border {
  border: 2px solid #ffffff !important;
}

.app-content-container {
  padding: 0px !important;
}

.card-img {
  height: auto;
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .card-img {
    width: auto;
    height: 42px;
  }
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .card-img {
    width: auto;
    height: 42px;
  }
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .card-img {
    width: auto;
    height: 42px;
  }
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .card-img {
    width: auto;
    height: 42px;
  }
}
</style>
